import "./styles.css";
import Soundtrack from "../../sounds/snips_1.mp3";
import { useState } from "react";

export const NavBar = () => {
  const isAuthenticated =
    sessionStorage.getItem("profile") === null ? false : true;

  const [isPlaying, setIsPlaying] = useState(false);

  const toggleMenu = (e) => {
    const menu = document.getElementById("sub-menu");
    menu.className === "sub=menu"
      ? (menu.className += "  hidden")
      : (menu.className = "sub=menu");
  };
  const toggleSound = () => {
    const player = document.getElementById("soundtrack");
    console.log(player);
    
    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }
    setIsPlaying(!isPlaying);
  };


  

  return (
    <div className="topnav" id="myTopnav">
      <div className="burger fa-solid fa-bars" onClick={toggleMenu}></div>
      <div id="sub-menu" className="sub-menu hidden">
        <a href="/">{"Home"}</a>
        <a href="/gigs">{"Gigs"}</a>
        <a href="/band">{"Meet the gang"}</a>
        <button onClick={toggleSound}>
          {isPlaying ? "Pause " : "Play "}Soundtrack
        </button>
        <span hidden={!isAuthenticated}>
          <a href="/tunes">{"Tunes"}</a>
        </span>
        <a href="/login">{isAuthenticated ? "Log Out" : "Login/Register"}</a>
      </div>
      <audio id="soundtrack" src={Soundtrack} loop />
    </div>
  );
};

export default NavBar;
